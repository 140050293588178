// * React Hooks
import React, { lazy, Suspense, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

// * Routes
import * as URL from "./Routes";
import ProtectedRoute from "src/helpers/ProtectedRoute";

// * Pages
const Login = lazy(() => import("../views/pages/Login/Login"));
const Register = lazy(() => import("../views/pages/register/Register"));
const Page404 = lazy(() => import("../views/pages/page404/Page404"));
const Page505 = lazy(() => import("../views/pages/page500/Page500"));
const Dashboard = lazy(() => import("../views/dashboard/Dashboard"));

const NewsList = lazy(() => import("../views/pages/News/List"));
const NewsView = lazy(() => import("../views/pages/News/NewsView/NewsView"));
const AddNews = lazy(() => import("../views/pages/News/AddNews/AddNews"));
const EditNews = lazy(() => import("../views/pages/News/EditNews/EditNews"));

const ImagesList = lazy(() => import("../views/pages/Images/List"));
const AddImages = lazy(() => import("../views/pages/Images/AddMedia/AddMedia"));
const MediaView = lazy(() =>
  import("../views/pages/Images/MediaView/MediaView")
);
const EditMedia = lazy(() =>
  import("../views/pages/Images/EditMedia/EditMedia")
);

const VideoList = lazy(() => import("../views/pages/Videos/List"));
const AddVideo = lazy(() => import("../views/pages/Videos/AddVideo/AddVideo"));
const VideoView = lazy(() =>
  import("../views/pages/Videos/VideoView/VideoView")
);
const EditVideo = lazy(() =>
  import("../views/pages/Videos/EditVideo/EditVideo")
);

const CategoryList = lazy(() => import("../views/pages/Category/List"));
const AddCategory = lazy(() =>
  import("../views/pages/Category/AddNewsCategory/AddNewsCategory")
);

const EditCategory = lazy(() =>
  import("../views/pages/Category/EditNewsCategory/EditNewsCategory")
);

const VersionList = lazy(() => import("../views/pages/AppVersions/List"));
const AddVersion = lazy(() =>
  import("../views/pages/AppVersions/AddVersion/AddVersion")
);

const EditVersion = lazy(() =>
  import("../views/pages/AppVersions/EditVersion/EditVersion")
);

// * Page Layout Component
const Layout = lazy(() => import("../layout/Layout"));

// * Loader
const Loader = () => (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

export default function Router() {
  const Navigate = useNavigate();
  useEffect(() => {
    if (window.location.pathname === "/") Navigate("/login");
  });
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route
          path={URL.Login}
          element={
            <ProtectedRoute accessBy="non-authenticated">
              <Login />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.Dashboard}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<Dashboard />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.NewsList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<NewsList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddNews}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddNews />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.EditNewsId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<EditNews />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.ViewNews}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<NewsView />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.ImagesList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<ImagesList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddImages}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddImages />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.VideoList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<VideoList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddVideo}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddVideo />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.ViewImages}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<MediaView />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.EditImagesId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<EditMedia />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.ViewVideo}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<VideoView />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.EditVideoId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<EditVideo />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.NewCategoryList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<CategoryList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddNewsCategory}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddCategory />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.EditCategoryId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<EditCategory />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AppVersionList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<VersionList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddVersion}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddVersion />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.EditVersionId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<EditVersion />} />
            </ProtectedRoute>
          }
        />

        <Route path={URL.Register} element={<Register />} />
        <Route path={URL.Page500} element={<Page505 />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Suspense>
  );
}
