export const Register = "/register";
export const Login = "/login";
export const Page404 = "/404";
export const Page500 = "/505";
export const Dashboard = "/dashboard";

export const NewsList = "/news/list";
export const AddNews = "/news/add";
export const EditNews = "/news/edit";
export const ViewNews = "/news/view/:id";
export const EditNewsId = "/news/edit/:id";

export const ImagesList = "/media/list";
export const AddImages = "/media/add";
export const EditImage = "/media/edit";
export const ViewImages = "/media/view/:id";
export const EditImagesId = "/media/edit/:id";

export const VideoList = "/video/list";
export const AddVideo = "/video/add";
export const EditVideo = "/video/edit";
export const ViewVideo = "/video/view/:id";
export const EditVideoId = "/video/edit/:id";

export const NewCategoryList = "/news/category/list";
export const AddNewsCategory = "/news/category/add";
export const EditNewCategory = "/news/category/edit";
export const EditCategoryId = "/news/category/edit/:id";

export const AppVersionList = "/app/version/list";
export const AddVersion = "/app/version/add";
export const EditVersion = "/app/version/edit";
export const EditVersionId = "/app/version/edit/:id";
